// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.news-section {
    padding: 10px 0; 
    overflow: hidden; 
    position: relative; 
    white-space: nowrap;
    background-color: #f9f9f9;
    border: 1px solid #ddd; 
}

.marquee {
    display: flex; 
    overflow: hidden; 
    position: relative; 
    height: 50px;
    align-items: center;
}

.marquee-content {
    margin-left: 100%;
    display: flex; 
    animation: scroll 130s linear infinite;
}

.marquee-item {
    padding: 0 22px; 
    font-size: 16px; 
    line-height: 50px;
}

@keyframes scroll {
    0% {
        transform: translateX(100); 
    }
    100% {
        transform: translateX(-100%); 
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/NewsSection/NewsSection.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,yBAAyB;IACzB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,sCAAsC;AAC1C;;AAEA;IACI,eAAe;IACf,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI;QACI,0BAA0B;IAC9B;IACA;QACI,4BAA4B;IAChC;AACJ","sourcesContent":[".news-section {\n    padding: 10px 0; \n    overflow: hidden; \n    position: relative; \n    white-space: nowrap;\n    background-color: #f9f9f9;\n    border: 1px solid #ddd; \n}\n\n.marquee {\n    display: flex; \n    overflow: hidden; \n    position: relative; \n    height: 50px;\n    align-items: center;\n}\n\n.marquee-content {\n    margin-left: 100%;\n    display: flex; \n    animation: scroll 130s linear infinite;\n}\n\n.marquee-item {\n    padding: 0 22px; \n    font-size: 16px; \n    line-height: 50px;\n}\n\n@keyframes scroll {\n    0% {\n        transform: translateX(100); \n    }\n    100% {\n        transform: translateX(-100%); \n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
