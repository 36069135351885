// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner-section {
    overflow: hidden; /* Prevent horizontal scrolling */
  }
  
  .banner-carousel {
    position: relative;
    max-width: 100%; /* Ensure the carousel doesn't exceed the screen width */
    margin: 0 auto; /* Center the carousel */
  }
  
  .slide-item {
    height: 100%; /* Ensure the slides take full height */
  }
  
  .image-layer {
    height: 100%; /* Make sure the image layer covers the entire slide */
    background-size: cover; /* Ensure the background image covers the slide */
    background-position: center; /* Center the background image */
  }
  
  @media (max-width: 650px) {
    .banner-carousel .slide-item {
      padding: 90px 0px 150px 0px !important;
  }
}

@media(max-width: 499px) {
  .banner-carousel .slide-item .image-layer {
      top: 40px !important;
      height: 65% !important;
  }
}
  `, "",{"version":3,"sources":["webpack://./src/components/Corousel/Corousel.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB,EAAE,iCAAiC;EACrD;;EAEA;IACE,kBAAkB;IAClB,eAAe,EAAE,wDAAwD;IACzE,cAAc,EAAE,wBAAwB;EAC1C;;EAEA;IACE,YAAY,EAAE,uCAAuC;EACvD;;EAEA;IACE,YAAY,EAAE,sDAAsD;IACpE,sBAAsB,EAAE,iDAAiD;IACzE,2BAA2B,EAAE,gCAAgC;EAC/D;;EAEA;IACE;MACE,sCAAsC;EAC1C;AACF;;AAEA;EACE;MACI,oBAAoB;MACpB,sBAAsB;EAC1B;AACF","sourcesContent":[".banner-section {\n    overflow: hidden; /* Prevent horizontal scrolling */\n  }\n  \n  .banner-carousel {\n    position: relative;\n    max-width: 100%; /* Ensure the carousel doesn't exceed the screen width */\n    margin: 0 auto; /* Center the carousel */\n  }\n  \n  .slide-item {\n    height: 100%; /* Ensure the slides take full height */\n  }\n  \n  .image-layer {\n    height: 100%; /* Make sure the image layer covers the entire slide */\n    background-size: cover; /* Ensure the background image covers the slide */\n    background-position: center; /* Center the background image */\n  }\n  \n  @media (max-width: 650px) {\n    .banner-carousel .slide-item {\n      padding: 90px 0px 150px 0px !important;\n  }\n}\n\n@media(max-width: 499px) {\n  .banner-carousel .slide-item .image-layer {\n      top: 40px !important;\n      height: 65% !important;\n  }\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
