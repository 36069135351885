// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 767px) {
    .departments-style-two {
        padding: 58px 0px 40px 0px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Awards/Awards.css"],"names":[],"mappings":"AAAA;IACI;QACI,qCAAqC;IACzC;AACJ","sourcesContent":["@media only screen and (max-width: 767px) {\n    .departments-style-two {\n        padding: 58px 0px 40px 0px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
