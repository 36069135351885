// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 767px) {
    .about-style-three {
        padding: 70px 0px !important;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/History/History.css"],"names":[],"mappings":"AAAA;IACI;QACI,4BAA4B;IAChC;AACJ","sourcesContent":["@media only screen and (max-width: 767px) {\n    .about-style-three {\n        padding: 70px 0px !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
