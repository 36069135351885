// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 499px) {
    .container-fluid1 {
        margin-top: -30px;
        padding-top: 0px;

    }
}`, "",{"version":3,"sources":["webpack://./src/components/ServicesHome/ServiceHome.css"],"names":[],"mappings":"AAAA;IACI;QACI,iBAAiB;QACjB,gBAAgB;;IAEpB;AACJ","sourcesContent":["@media (max-width: 499px) {\n    .container-fluid1 {\n        margin-top: -30px;\n        padding-top: 0px;\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
